<template>
  <v-container>
    <div class="title grey--text text--darken-4 mb-1">
      Consent Options
    </div>
    <div class="body-1 grey--text text--darken-2 mb-4">
      Choose the `Consent Format` for this campaign:<br />
      Default - Consent form submission required<br />
      Email on file - Valid email required<br />
      (Beta) Multi-Consent - Consent form to opt-in for e-notice and e-voting
    </div>

    <v-layout row justify-space-between>
      <v-flex xs4>
        <!-- Consent Format Selector -->
        <v-select
          v-model="consentFormatSelect"
          :items="consentFormats"
          item-value="value"
          item-text="label"
          chips
          :value="options.consentFormat || 'default'"
          label="Consent Format"
          @change="onFormatChange($event)"
        />
      </v-flex>
      <v-flex xs4>
        <!-- Consent Template Selector -->
        <!-- If there are options available, display them -->
        <v-select
          v-if="consentTemplateOptions"
          v-model="consentTemplateSelect"
          :items="consentTemplateOptions"
          item-value="value"
          item-text="label"
          item-disabled="disabled"
          chips
          label="Consent Template"
          persistent-hint
          min="6"
          max="15"
          @change="onTemplateChange($event)"
        />
      </v-flex>
    </v-layout>

    <!-- Display Custom editor or Default template -->
    <div v-if="displayCustomEditor">
      <!-- Custom Editor -->
      <custom
        :template="template"
        :custom-fields="customFields"
        :consentTemplateSelect="consentTemplateSelect"
        :consentTemplateName="consentTemplateName"
        :consentFormatSelect="consentFormatSelect || 'default'"
        @update="onChange('consentTemplate', $event)"
      />
    </div>
    <div v-else>
      <!-- Template preview -->
      <preview
        :consentTemplateSelect="consentTemplateSelect"
        :consentTemplateName="consentTemplateName"
        :htmlPreview="htmlPreview"
        :consentFormatSelect="consentFormatSelect || 'default'"
      />
    </div>

    <!-- Signature setting -->
    <v-layout class="mt-3">
      <div class="pr-3">
        <v-checkbox
          :true-value="true"
          :false-value="false"
          v-model="displayConfirmSignature"
          @change="onChange('options.displayConfirmSignature', $event)"
        />
      </div>

      <div>
        <h5 class="subheading">
          (BETA) Enable signature requirement for Consent app
        </h5>
        <p class="grey--text text--darken-2">
          Check this to enable signature requirement for Consent app
        </p>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Preview from './MeetingSettingsConsentOptionsInputPreview';
import Custom from './MeetingSettingsConsentOptionsInputCustom';

export default {
  name: 'MeetingSettingsConsentOptionsInput',
  components: {
    Preview,
    Custom
  },
  props: {
    template: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    shortCode: {
      type: String,
      default: ''
    }
  },
  watch: {
    consentFormatSelect() {
      // If format selection changes, set template back to default
      this.consentTemplateSelect = 'default';
    }
  },
  data() {
    return {
      // Track selector state locally
      consentFormatSelect: _.get(this.options, 'consentFormat', 'default'),
      consentTemplateSelect: _.get(this.options, 'consentTemplate', 'default'),

      // Selector options
      consentFormats: [
        { label: 'Default', value: 'default', disabled: false },
        { label: 'Email on file', value: 'email-on-file', disabled: false },
        {
          label: 'Multi-Consent (beta)',
          value: 'multi-consent',
          disabled: false
        }
      ],
      consentTemplates: [
        {
          label: 'Ontario',
          value: 'default'
        },
        {
          label: 'Florida',
          value: 'florida'
        },
        {
          label: 'Alberta',
          value: 'alberta'
        },
        {
          label: 'British Columbia',
          value: 'british columbia'
        },
        {
          label: 'Custom',
          value: 'custom'
        }
      ],
      multiConsentTemplates: [
        {
          label: 'Default',
          value: 'default'
        },
        {
          label: 'Custom',
          value: 'custom'
        }
      ],
      displayConfirmSignature: false
    };
  },
  computed: {
    ...mapGetters('meetings/consentTemplates', ['htmlPreview']),
    consentTemplateName() {
      return _.get(this.options, 'consentTemplate', 'default');
    },
    customFields() {
      const customUnitFields = this.options.customUnitFields || [];
      return customUnitFields.map(field => `customUnitFields.${field}`);
    },
    consentTemplateOptions() {
      switch (this.consentFormatSelect) {
        case 'default':
          return this.consentTemplates;
        case 'multi-consent':
          return this.multiConsentTemplates;
        default:
          return null;
      }
    },
    displayCustomEditor() {
      // Formats that allow custom editing
      const validFormats = ['default', 'multi-consent'];

      return (
        this.consentTemplateSelect === 'custom' &&
        validFormats.includes(this.consentFormatSelect)
      );
    }
  },
  methods: {
    onChange(key, value) {
      this.$emit('update', { key, value });
    },
    onTemplateChange(value) {
      this.onChange('options.consentTemplate', value);
    },
    onFormatChange(value) {
      this.onChange('options.consentFormat', value);
    }
  },
  mounted() {
    this.displayConfirmSignature = _.get(
      this.options,
      'displayConfirmSignature',
      false
    );
  }
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>
